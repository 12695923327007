import React from "react";


const Form = (props) => {

  return (
    <div className="py-10 px-5">
      <div class="elfsight-app-8571506d-1f2d-418d-9bc8-38f12ec6c1fc" data-elfsight-app-lazy></div>
    </div >
  );
};

export default Form;


// variable del formulario:
// tname=""
// tlname=""
// temail=""
// tphone=""
// taddress=""
// ttypeservice=""
// tbestway=""
// tcheckphone=""
// tcheckcorreo=""
// tmessage=""
// tbutton=""
// isenglish="true"
